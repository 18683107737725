/*Package Imports*/
import React, { useRef, useState } from "react";
import { Card, CardBody, CardHeader, Container, Button, Label, FormGroup, CustomInput } from "reactstrap";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux"; /*BackO_324 */
/*App Imports*/
import { authActions } from "../../../data/redux/store/auth";
import { memberActions } from "../../../data/redux/store/member";
import { organizationActions } from "../../../data/redux/store/organization";
import { serviceActions } from "../../../data/redux/store/service";
import { locationsActions } from "../../../data/redux/store/locations";
import { usePostAuthorizeMutation } from "../../../data/redux/store/backofficeApi";

const SignInForm = (props) => {
  const dispatch = useDispatch(); //auth state: set token email and login, all states: rehydrate
  const [failedCheck, setFailedCheck] = useState("");
  const [rememberMe, setRememberMe] = useState(localStorage.getItem("rememberMe") === "true");
  const [enteredEmail, setEnteredEmail] = useState(localStorage.getItem("rememberMe") === "true" && !failedCheck ? localStorage.getItem("user") : "");
  const [enteredPassword, setEnteredPassword] = useState("");
  const emailInputRef = useRef(localStorage.getItem("rememberMe") === "true" && !failedCheck ? localStorage.getItem("user") : "");
  const passwordInputRef = useRef("");
  const currentColorMode = useSelector((state) => state.theme.currentColorMode);/*BackO_324 */

  //endpoint api/authorize
  const [authorize] = usePostAuthorizeMutation();

  //on any key stroke in inputs, set value from input field
  const getUserInput = (type) => {
    if (type === "email") {
      setEnteredEmail(emailInputRef.current.value);
    }
    if (type === "password") {
      setEnteredPassword(passwordInputRef.current.value);
    }
  };

  //Attempt sign in with enter key
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      checkCredentials();
    }
  };

  //Member is authorized, rehydrate states to persist on refresh and set auth state items
  const passCheck = () => {
    localStorage.setItem("rememberMe", rememberMe);
    localStorage.setItem("user", rememberMe ? enteredEmail : "");

    //Used for react persist root state
    dispatch(authActions.REHYDRATE());
    dispatch(memberActions.REHYDRATE());
    dispatch(organizationActions.REHYDRATE());
    dispatch(serviceActions.REHYDRATE());
    dispatch(locationsActions.REHYDRATE());

    dispatch(authActions.setEmail(enteredEmail));
    dispatch(authActions.login());
  };

  //Trigger usePostAuthorizeMutation, if we get a response, check to make sure the response is not orgLicenseInactive and is not false, if it does not, pass check, else fail check
  const checkCredentials = () => {
    if (enteredEmail !== "" || enteredPassword !== "") {
      const authbody = { Email: enteredEmail, Password: enteredPassword };
      authorize({ body: authbody })
        .unwrap()
        .then((fulfilled) => {
          if (!fulfilled.includes("orgLicenseInactive") && !fulfilled.includes("false")) {
            dispatch(authActions.setToken(fulfilled));
            passCheck();
          } else {
            if (fulfilled.includes("orgLicenseInactive")) {
              setFailedCheck("OrganizationLicenseInactive");
            } else if (fulfilled.includes("false")) {
              setFailedCheck("IncorrectCredentials");
            }
          }
        })
        .catch((rejected) => setFailedCheck(true));
    } else {
      setFailedCheck("IncorrectCredentials");
    }
  };

  return (
    <Container fluid>
      <Card className="shadow" style={{ backgroundColor: currentColorMode.backgroundColor, textTransform:"uppercase" }}/*BackO_324 */ onKeyPress={onKeyUp}>
        <CardHeader>
          <h2 className="mt-2" style={{ textAlign: "center", color: currentColorMode.color}}/*BackO_324 */>
            Welcome to MIMO Administrator
          </h2>
          <h6 style={{ textAlign: "center", color: currentColorMode.color }}/*BackO_324 */ className="card-subtitle">
            Sign in to your account to continue
          </h6>
        </CardHeader>
        <CardBody>
          <AvForm style={{textTransform:"uppercase"}}/*BackO_324 */>
            <AvGroup>
              <Label for="email" style={{ color: currentColorMode.color }}/*BackO_324 */>
                Email
              </Label>
              <AvInput name="email" id="email" ref={emailInputRef} onChange={() => getUserInput("email")} value={rememberMe ? localStorage.getItem("user") : ""} />
            </AvGroup>
            <AvGroup>
              <Label for="password" style={{ color: currentColorMode.color }}/*BackO_324 */>
                Password
              </Label>
              <AvInput 
                type="password" 
                name="password" 
                id="password" 
                ref={passwordInputRef} onChange={() => getUserInput("password")} 
                onKeyPress={onKeyUp} 
                />
              {failedCheck !== "" ? (
                <h5 className="mt-2 ml-1" style={{ color: "#C00707"}} /*BackO_324 */>
                  {failedCheck === "OrganizationLicenseInactive" ? "Member License Expired. Please contact MIMO support." : "Incorrect Credentials. Please try again."}
                </h5>
              ) : (
                ""
              )}
            </AvGroup>
            <div style={{color:currentColorMode.color}} /*BackO_324 */>
              <CustomInput type="checkbox" id="rememberMe" label="Remember me next time" defaultChecked={rememberMe} onChange={() => setRememberMe(!rememberMe)} className={localStorage.getItem("theme") /*BackO_324 === "darkMode" ? "white-font" : "" */ } />
            </div>
            <FormGroup style={{ textAlign: "center" }}>
              <Button className="mt-3" color="primary" size="lg" onClick={checkCredentials}>
                SIGN IN
              </Button>
            </FormGroup>
            <div className="text-center mt-3">
              <em onClick={props.onClick} style={{ cursor: "pointer", color: currentColorMode.color }}>
                FORGOT PASSWORD?
              </em>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Container>
  );
};

export default SignInForm;
